<template>
    <Form @submit="handleSubmit">
        <div class="modal" v-if="modelValue">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <div class="modal_info">
                        <h1>Client Portal <span>Cover Settings</span></h1>
                    </div>
                    <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="flex flex-col items-center items-start bg-primary-light2 p-3.5 mb-7 rounded text-sm font-normal leading-6 text-dark-gray text-center">
                        <img src="@/assets/images/bulb.svg" class="w-5 h-auto -mt-6 mb-2.5">
                        These are basic settings, and you can customize them extensively within your client portal settings.
                    </div>
                    <div class="setting_wpr">
                        <div class="edit_section mb-7">
                            <div class="form_grp py-2">
                                <div class="group_item">
                                    <h3 class="sub_heading2 mb-0 flex items-center">
                                        Headline
                                        <label for="is_portal_headline" class="switch_option capsule_btn ml-auto p-0">
                                            <input type="checkbox" id="is_portal_headline" :true-value="1" :false-value="0" v-model="portalForm.is_headline" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </h3>
                                    <div class="field_wpr bg-gray-12 my-2" v-if="portalForm.is_headline">
                                        <Field autocomplete="off" type="text" name="portal_headline_text" v-model="portalForm.headline" placeholder="Welcome To Your Client Portal" />
                                    </div>
                                    <ErrorMessage name="portal_headline_text" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp py-2">
                                <div class="group_item">
                                    <h3 class="sub_heading2 mb-0 flex items-center">
                                        Sub Headline
                                        <label for="is_portal_description" class="switch_option capsule_btn ml-auto p-0">
                                            <input type="checkbox" id="is_portal_description" :true-value="1" :false-value="0" v-model="portalForm.is_subheadline" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </h3>
                                    <div class="field_wpr bg-gray-12 my-2" v-if="portalForm.is_subheadline">
                                        <Field autocomplete="off" type="text" name="portal_description_text" v-model="portalForm.description" placeholder="This is where the magic happens!" />
                                    </div>
                                    <ErrorMessage name="portal_description_text" class="text-danger" />
                                </div>
                            </div>
                            <h3 class="sub_heading2 mt-3.5 mb-2 flex items_center">
                                Cover Image
                                <a href="https://www.canva.com/design/DAGOTThEbj8/Om_FvXX7ZVI0aN3NjL53mQ/view?utm_content=DAGOTThEbj8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="flex items-center justify-center py-1 px-2.5 ml-auto bg-white border-1 border-primary-light rounded-20 text-xs text-primary font-medium text-center no-umderline"><img src="@/assets/images/canva.png" class="h-2.5 w-auto"></a>
                            </h3>
                            <div class="image_setting">
                                <image-library v-model="portalForm.cover" image-type="public-cover" upload-text="Cover" is-free-images/>
                            </div>
                            <h3 class="sub_heading2 mt-7 mb-2 flex items_center">
                                Small Cover Image
                                <a href="https://www.canva.com/design/DAGOTdkLors/VhFb6jHGZqLc-US_C27UiQ/view?utm_content=DAGOTdkLors&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank" class="flex items-center justify-center py-1 px-2.5 ml-auto bg-white border-1 border-primary-light rounded-20 text-xs text-primary font-medium text-center no-umderline"><img src="@/assets/images/canva.png" class="h-2.5 w-auto"></a>
                            </h3>
                            <div class="image_setting">
                                <image-library v-model="portalForm.small_cover" image-type="public-cover-small" upload-text="Cover" is-free-images/>
                            </div>
                        </div>
                        <div class="edit_section mt-5">
                            <h3 class="sub_heading2 mb-0 flex items-center">
                                Add Image Overlay
                                <label for="overlay" class="switch_option capsule_btn ml-auto">
                                    <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="portalForm.has_cover_overlay" hidden>
                                    <div><span></span></div>
                                </label>
                            </h3>
                            <div v-if="portalForm.has_cover_overlay">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Overlay Color</label>
                                        <Field autocomplete="off" name="overlay_color" v-model="portalForm.overlay_color" type="text" label="element color">
                                            <color-picker v-model="portalForm.overlay_color"/>
                                        </Field>
                                        <ErrorMessage name="overlay_color" class="text-danger" />
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Overlay Opacity</label>
                                        <div class="field_wpr bg-gray-12">
                                            <Field autocomplete="nofill" name="overlay_opacity" v-model="portalForm.overlay_opacity" type="number" min="0" max="100" placeholder="90" label="element color" />
                                        </div>
                                        <ErrorMessage name="overlay_opacity" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button class="btn save_btn">
                        <i class="fa fa-spin fa-spinner" v-if="setupWizardLoader"></i>
                        {{ setupWizardLoader ? 'Saving' : 'Save' }}
                    </button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    const ImageLibrary = defineAsyncComponent(() => import('@/components/image-library/ImageLibrary'))

    export default {
        data () {
            return {
                portalForm: {}
            };
        },

        props: {
            modelValue: Boolean,
            form: Object,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.portalForm = JSON.parse(JSON.stringify(vm.form));
                }
            },
        },

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageLibrary
        },

        computed: mapState({
            setupWizardLoader: state => state.adminSettingModule.setupWizardLoader,
        }),

        methods: {
            ...mapActions({
                updatePortalSetting: 'adminSettingModule/updatePortalSetting',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmit (form, { setFieldError}) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.portalForm));

                params.setFieldError = setFieldError;

                vm.updatePortalSetting(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>
